import List from "@mui/material/List";
import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetAxiosInstance} from "../common/GetAxiosInstance";


const MotifsAutocomplete = ({onMotifsChange}) => {
  const [motifsData, setMotifsData] = useState([])
    const axiosi = GetAxiosInstance();

  useEffect(() => {
    axiosi.get(`/api/motifs`).then((res) => setMotifsData(res.data))
  }, [])

  return (
    <Autocomplete
      multiple filterSelectedOptions
      id="motifs-auto"
      options={motifsData}
      onChange={(e, val) => onMotifsChange(val.map(x => x.site))}
      getOptionLabel={(option) => option.site || ""}
      renderOption={(props, option) =>
        (
          <List {...props} key={option.id}>
            <ListItem>
              <ListItemText
                primary={option.site}
                secondary={
                  <>
                    <span>id: {option.id}</span>
                  </>
                } />
            </ListItem>
          </List>
        )}
      renderInput={(params) =>
        <TextField {...params} label="Motifs" placeholder="Motifs" />}
    />
  )
}

export default MotifsAutocomplete;