import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Divider,
  TextField
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const AddMotifDialog = ({open, handleClose, handleSuccess, setMotifData}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Add new motif
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{py: 0.5}}>
          <Grid item xs={12}>
            <TextField id="new-motif" label="New motif" variant="outlined"
                       onChange={(e) => setMotifData(e.target.value)} type="text" fullWidth size="small"/>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="success" onClick={handleSuccess}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddMotifDialog;