import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";


const CircularProgressWithLabel = ({current, max}) =>
{
  current = current ? current : 0
  max = max ? max : 1
  let val = 100 * current / max
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={val} size={32} thickness={7} sx={{borderRadius: "50%", boxShadow: `inset 0 0 0 ${7/44*32}px #d1d1d1`}}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(val)}%`}
        </Typography>
      </Box>
    </Box>
  )
}


export default CircularProgressWithLabel;