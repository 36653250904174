import * as yup from "yup";
import {SequenceType} from "../../common/enums";

const initialValues = {
  name: "",
  algo: [],
  codonUsage: "default.default",
  sequenceType: 1,
  aaSeq: "",
  customAA: "",
  coding_region_name: "",
  coding_region_link: "",
  utr5: "",
  utr5_name: "",
  utr5_link: "",
  utr3: "",
  utr3_name: "",
  utr3_link: "",
  utrs: "",
  cdsSeq: "",
  conSeq: "",
  customSeq: "",
}

const validationSchema = yup.object({
  name: yup.string('Enter a Name').required('Name is required'),
  algo: yup.array().min(1, 'Algorithm is required'),
  codonUsage: yup.string('Select a codon usage').required('Codon usage is required'),
  sequenceType: yup.number().nullable(false),
  aaSeq: yup.string().when(['sequenceType'], {
    is: (sequenceType) => sequenceType === SequenceType.aaSequence,
    then: yup.string().required("Please select Amino Acid sequence")
  }),
  utr5: yup.string("Select 5' UTR"),
  utr3: yup.string("Select 3' UTR"),
  utrs: yup.string()
    .when(['sequenceType', 'utr5', 'utr3'], {
      is: (sequenceType, utr5, utr3) =>
        ((utr3 && !utr5) || (!utr3 && utr5)),
      then: yup.string().required("Either select both UTR's or select neither")
    }),
  cdsSeq: yup.string().when(['sequenceType'], {
    is: (sequenceType) => sequenceType === SequenceType.cdsSequence,
    then: yup.string().required("Please select a Coding Sequence")
      .test('valid codons', "Coding region sequence length must be divisible by 3",
        (value) => value && value.length % 3 === 0)
  }),
  conSeq: yup.string().when(['sequenceType'], {
    is: (sequenceType) => sequenceType === SequenceType.conSequence,
    then: yup.string().required("Please select a Construct Sequence")
  }),
  customSeq: yup.string().when(['sequenceType'], {
    is: (sequenceType) => sequenceType === SequenceType.customSequence,
    then: yup.string().required("Please enter a DNA/RNA seq")
      .test('valid codons', "Coding region sequence length must be divisible by 3",
      (value) => value && value.length % 3 === 0)
  }),
  customAA: yup.string().when(['sequenceType'], {
    is: (sequenceType) => sequenceType === SequenceType.customAA,
    then: yup.string().required("Please enter a AA seq")
  }),
});

function setSequenceType(formik, seqType){
  formik.setFieldValue('sequenceType', seqType)
  formik.setFieldValue('aaSeq', "")
  formik.setFieldValue('conSeq', "")
  formik.setFieldValue('cdsSeq', "")
  formik.setFieldValue('utr5', "")
  formik.setFieldValue('utr3', "")
  formik.setFieldValue('customSeq', "")
  formik.setFieldValue('utr5_name', "")
  formik.setFieldValue('utr5_link', "")
  formik.setFieldValue('utr3_name', "")
  formik.setFieldValue('utr3_link', "")
  formik.setFieldValue('coding_region_name', "")
  formik.setFieldValue('coding_region_link', "")
}

export {initialValues, validationSchema, setSequenceType};