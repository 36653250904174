import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import {GetAxiosInstance} from "../common/GetAxiosInstance";

const AlgoTypeInput = ({formik}) => {
  const [algoData, setAlgoData] = useState([])
    const axiosi = GetAxiosInstance();

  useEffect(() => {
    axiosi.get(`/api/algos`).then((res) => {
      setAlgoData(res.data)
    })
  }, [])

  return (
    <Autocomplete
      multiple filterSelectedOptions
      disablePortal
      required
      id="algo"
      options={algoData}
      onChange={(event, inputValue) =>  formik.setFieldValue("algo", inputValue.map(x => x.name))}
      getOptionLabel={(option) => option.desc || ""}
      renderOption={(props, option) =>{
        return (
          <List {...props} key={option.name}>
            <ListItem>
              <ListItemText
                primary={option.desc}
                secondary={
                <>
                  <span style={{fontSize: "small"}}>{option.name}</span> <br />
                </>}
              />
            </ListItem>
          </List>
        )
      }
      }
      renderInput={(params) =>
        <TextField {...params} label="Algorithm Types*"
                   error={formik.touched.algo && Boolean(formik.errors.algo)}
                   helperText={formik.touched.algo && formik.errors.algo}
                   InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
    />
  )
}

export default AlgoTypeInput;