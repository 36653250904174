import List from "@mui/material/List";
import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetAxiosInstance} from "../common/GetAxiosInstance";


const RestrictionEnzymesAutocomplete = ({enzymes, onEnzymesChange}) => {
  const [enzymeData, setEnzymeData] = useState([])
  const [loading, setLoading] = useState(false)
    const axiosi = GetAxiosInstance();


  useEffect(() => {
    setLoading(true)
    axiosi.get(`/api/restriction-enzymes`)
      .then((res) => setEnzymeData(res.data))
      .finally(() => setLoading(false))
  }, [])

  return (
    <Autocomplete
      multiple filterSelectedOptions
      id="enzymes-auto"
      options={enzymeData}
      onChange={(e, val) => onEnzymesChange(val.map(x => x.name))}
      getOptionLabel={(option) => option.name}
      loading={loading}
      renderOption={(props, option) =>
        (
          <List {...props} key={option.name}>
            <ListItem>
              <ListItemText
                primary={option.name}
                secondary={
                  <>
                    <span>Site: {option.site}</span>
                  </>
                } />
            </ListItem>
          </List>
        )}
      renderInput={(params) =>
        <TextField {...params} label="Restriction Enzyme" placeholder="Restriction Enzyme" />}
    />
  )
}

export default RestrictionEnzymesAutocomplete;