import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {useParams} from "react-router-dom";
import PageHeader from "../components/common/page-header";
import Grid from "@mui/material/Unstable_Grid2";
import {Button, ButtonGroup, Card, CardActions, CardContent} from "@mui/material";
import "./styles/simulations.css"
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import GraphPlotDialog from "../components/graphs/graph-plot";
import {GetAxiosInstance} from "../components/common/GetAxiosInstance";

const cols = [
  { field: 'id', headerName: 'Sequence Id', type: 'number', flex: 1 },
  { field: 'gu_no', headerName: 'GU pairs', type: 'number', flex: 1 },
  { field: 'mfe', headerName: 'MFE', type: 'number', flex: 1 },
  { field: 'gc', headerName: 'GC content', type: 'number', flex: 1 },
  { field: 'codon_usage', headerName: 'Codon Usage', type: 'number', flex: 1 },
  { field: 'restriction', headerName: 'Restriction', type: 'number', flex: 1 },
  { field: 'repeat', headerName: 'Repeat', type: 'number', flex: 1 },
  { field: 'fitness_score', headerName: 'Fitness', type: 'number', flex: 2 },
  { field: 'gen', headerName: 'Gen', type: 'number', flex: 1 },
  { field: 'create_time', headerName: 'Create Time', flex: 2},
  { field: 'seq', headerName: 'CDS', flex:3},
]

const SimulationSequenceList = () => {
  const [tableData, setTableData] = useState([])
  const [simulationData, setSimulationData] = useState(null)
  const [loading, setLoading] = useState(false)
  let { id } = useParams();
  const [algoData, setAlgoData] = useState([])
  const [simAlgo, setSimAlgo] = useState("")
  const [openGraphPlot, setOpenGraphPlot] = useState(false)
  const [plotData, setPlotData] = useState(null)
  const axiosi = GetAxiosInstance();

  function handleClick(plotType){
    let data = {
      [`${simulationData.algo}-${simulationData.id}`]: simulationData.id
    }

    axiosi.post(`/api/plot/${plotType}`, data).then((res) => {
      setOpenGraphPlot(true)
      setTimeout(() => setPlotData(res.data), 100)
    })
  }

  useEffect(() => {
    setLoading(true);

    axiosi.get(`/api/sequences/${id}`)
        .then((res) => {
          setTableData(res.data)
        });

    axiosi.get(`/api/simulations/${id}`)
      .then((res) => setSimulationData(res.data));

    axiosi.get(`/api/algos`).then((res) =>  setAlgoData(res.data));

    setLoading(false)
  }, [id])

  useEffect(() => {
    if (!!simulationData && !!algoData){
      let algo = algoData.find((x) => x.name === simulationData.algo)
      if (algo)
        setSimAlgo(algo.desc)
    }
  }, [algoData, simulationData])

  return (
      <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
    >
      <GraphPlotDialog open={openGraphPlot} setOpen={setOpenGraphPlot} plotData={plotData} />
      <PageHeader msg={`Simulation with id: ${id}`} />
      <Grid container spacing={3}>
        <Grid item xs={12} >
          {
            simulationData && algoData &&
            <Card sx={{ m: 1, boxShadow: 3 }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} xl={6}>
                    <dl>
                      <dt>Simulation name: </dt><dd>{simulationData.name}</dd>
                      <dt>Algorithm: </dt><dd>{simAlgo}</dd>
                      <dt>Status: </dt><dd>{simulationData.status}</dd>
                      <dt>Max generation: </dt><dd>{simulationData.max_generation}</dd>
                      <dt>Sequence name: </dt><dd>{simulationData.coding_region_name}</dd>
                      <dt>Sequence link: </dt><dd>{simulationData.coding_region_link}</dd>
                      <dt>Seq: </dt><dd><div style={{maxWidth: "25em"}} className="sequence-overflow">{simulationData.coding_region}</div></dd>
                      <dt>Description: </dt><dd><div style={{maxWidth: "25em"}} className="sequence-overflow">{simulationData.desc}</div></dd>
                    </dl>
                  </Grid>
                  <Grid item xs={12} xl={6}>
                    <dl>
                      <dt>Start date: </dt><dd>{simulationData.start_time}</dd>
                      <dt>5' utr name: </dt><dd>{simulationData.utr5_name}</dd>
                      <dt>5' utr link: </dt><dd>{simulationData.utr5_link}</dd>
                      <dt>5' utr seq: </dt><dd><div style={{maxWidth: "25em"}} className="sequence-overflow">{simulationData.utr5_seq}</div></dd>
                      <dt>3' utr name: </dt><dd>{simulationData.utr3_name}</dd>
                      <dt>3' utr link: </dt><dd>{simulationData.utr3_link}</dd>
                      <dt>3' utr seq: </dt><dd><div style={{maxWidth: "25em"}} className="sequence-overflow">{simulationData.utr3_seq}</div></dd>
                      <dt>Graph: </dt>
                      <dd>
                        <ButtonGroup variant="outlined" color="primary" aria-label="Show graph plot" size="small">
                          <Button startIcon={<BarChartOutlinedIcon />} onClick={() => handleClick('3d')}>
                            3d graph
                          </Button>
                          <Button startIcon={<BarChartOutlinedIcon />} onClick={() => handleClick('3d-gc')}>
                            3d graph - GC
                          </Button>
                          <Button startIcon={<BarChartOutlinedIcon />} onClick={() => handleClick('2d')}>
                            Convergence plot
                          </Button>
                        </ButtonGroup>
                      </dd>
                    </dl>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                {/*<Button size="small">Learn More</Button>*/}
              </CardActions>
            </Card>
          }
        </Grid>
        <Grid item xs={12} style={{paddingTop: 30}}>
          <DataGrid autoHeight sx={{m: 1}}
                    rows={tableData}
                    density={'compact'}
                    columns={cols}
                    pageSize={15}
                    rowsPerPageOptions={[15]}
                    loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
    )
}

export default SimulationSequenceList;