import {Backdrop, Box, Button, ButtonGroup, Divider, TextField, Tooltip, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import React, {useEffect, useState} from "react";
import BenchlingAaInput from "../sequence/benchling-aa-input";
import CofoldTypeInput from "../cofold-type/cofold-type-input";
import AlgoTypeInput from "../algo-type/algo-type-input";
import CodonUsageInput from "../codon-usage/codon-usage-input";
import CustomSlider from "../sliders/custom-slider";
import RestrictionEnzymesAutocomplete from "../restriction-enzymes/restriction-enzymes-autocomplete";
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import {generateUUID} from "../../common/utils";
import {useNavigate} from "react-router-dom";
import CustomDialog from "../common/custom-dialog";
import MotifsAutocomplete from "../motifs/motifs-autocomplete";
import PageHeader from "../common/page-header";
import CustomDnaInput from "../sequence/custom-dna-input";
import BenchlingConstructInput from "../sequence/benchling-construct-input";
import {SequenceType} from "../../common/enums";
import { useFormik } from 'formik';
import {validationSchema, setSequenceType, initialValues} from "./validation-schema";
import BenchlingCdsInput from "../sequence/benchling-cds-input";
import {setLocalStorage} from "../common/autocomplete-options";
import CustomAAInput from "../sequence/custom-aa-input";
import {GetAxiosInstance} from "../common/GetAxiosInstance";

const NewRunComponent = () => {
  const navigate = useNavigate();
  const [cof, setCof] = useState("LINEAR_FOLD_V")
  const [repeatLength, setRepeatLength] = useState(20)
  const [generations, setGenerations] = useState(500)
  const [enzymes, setEnzymes] = useState([])
  const [motifs, setMotifs] = useState([])
  const [loading, setLoading] = useState(false)
  const [hasLoaded, setHasLoaded] = useState();
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [schema, setSchema] = useState(null)
  const axiosi = GetAxiosInstance();

  useEffect(() => {
    axiosi.get(`/api/benchling-schema`).then((res) => {
      setSchema(res.data)
    });
  }, [])
  useEffect(() =>{
    setLocalStorage(schema, setHasLoaded, axiosi)
  }, [schema])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values)
      handleSave()
    },
  });

  function handleSave() {
    console.log("handle save called")
    let v = formik.values
    let data = {
      'id': generateUUID(),
      'name': v.name,
      'aaCodingRegion': (v.sequenceType === SequenceType.aaSequence && v.aaSeq !== ""
        ? v.aaSeq
        : v.sequenceType === SequenceType.customAA && v.customAA !== ""
          ? v.customAA
          : "").toUpperCase(),
      'utr5_name': v.utr5_name,
      'utr5_link': v.utr5_link,
      'utr3_name': v.utr3_name,
      'utr3_link': v.utr3_link,
      'coding_region_name': v.coding_region_name,
      'coding_region_link': v.coding_region_link,
      'utr5': (v.sequenceType !== SequenceType.conSequence && v.utr5 !== "" ? v.utr5 : "").toUpperCase(),
      'utr3': (v.sequenceType !== SequenceType.conSequence && v.utr3 !== "" ? v.utr3 : "").toUpperCase(),
      'dnaCodingRegion': (v.sequenceType === SequenceType.cdsSequence && v.cdsSeq !== ""
        ? v.cdsSeq
        : v.sequenceType === SequenceType.conSequence && v.conSeq !== ""
          ? v.conSeq
          : v.sequenceType === SequenceType.customSequence && v.customSeq !== ""
            ? v.customSeq
            : "").toUpperCase(),
      'cof': cof,
      'algo': v.algo,
      'codonUsage': v.codonUsage,
      'motifs': motifs,
      'repeatLength': repeatLength,
      'generations': generations,
      'enzymes': enzymes,
      'is_construct': v.sequenceType === SequenceType.conSequence
    }
    console.log(data)
    setLoading(true)
    axiosi.post(`/api/new-run`, data).then((res) => {
      console.log(res.status)
      console.log(res.data)
      setOpenSuccess(true)
    })
      .catch(function (error) {
        console.log(error.toJSON());
        setOpenError(true)
      })
      .finally(() => setLoading(false));
  }


  return (
    (hasLoaded && !loading) ?
    <>
      <CustomDialog handleClose={() => {setOpenError(false);}} open={openError} msgTitle={"Error"} color={"error"}
                    msgContent={"Error while creating new run"} />
      <CustomDialog handleClose={() => {navigate("/run-history"); setOpenSuccess(false);}} open={openSuccess} msgTitle={"Success"} color={"success"}
                    msgContent={"Successfully added a new run to the queue!"} />

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
      >

        <form onSubmit={formik.handleSubmit}>
          <PageHeader msg={"New Run"} />
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
            <Tooltip title="The Amino Acid comes from Benchling">
              <Button onClick={(e) => setSequenceType(formik, SequenceType.aaSequence)}>Protein (AA)</Button>
            </Tooltip>
            <Tooltip title="The coding region comes from Benchling">
              <Button onClick={(e) => setSequenceType(formik, SequenceType.cdsSequence)}>Coding Sequence (CDS)</Button>
            </Tooltip>
            <Tooltip title="The construct sequence comes from Benchling">
              <Button onClick={(e) => setSequenceType(formik, SequenceType.conSequence)}>Construct (CON)</Button>
            </Tooltip>
            <Tooltip title="Any DNA/RNA can be entered">
              <Button onClick={(e) => setSequenceType(formik, SequenceType.customSequence)}>Custom DNA/RNA</Button>
            </Tooltip>
            <Tooltip title="Any AA can be entered">
              <Button onClick={(e) => setSequenceType(formik, SequenceType.customAA)}>Custom AA</Button>
            </Tooltip>
          </ButtonGroup>

          <Grid container spacing={3}>

            <Grid item xs={12} style={{paddingTop: 30}}>

              <Grid container rowSpacing={2}
                    columnSpacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ m: 0, p: 0 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Tooltip title="Name of the run. Required">
                    <TextField id="name" label="Name*" variant="standard" size="small"
                               value={formik.values.name}
                               onChange={formik.handleChange}
                               onBlur={formik.handleBlur}
                               error={formik.touched.name && Boolean(formik.errors.name)}
                               helperText={formik.touched.name && formik.errors.name}
                               fullWidth/>
                  </Tooltip>
                </Grid>
                <Tooltip placement="top" title="The number of generations the GA will run">
                  <Grid item xs={12} sm={6} md={6}>
                    <CustomSlider length={generations} onRepeatLengthChange={setGenerations} defaultValue={500}
                                  title={"Generations"} max={3000} min={20} step={50}/>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>

            <br/>
            <Typography color="gray" variant="body2" m="auto" style={{paddingTop: 20}}> Input sequence </Typography>

            {/*Input grid*/}

            <Grid item xs={12}>
              {
                formik.values.sequenceType === SequenceType.aaSequence
                  ? <BenchlingAaInput formik={formik} schema={schema}/>
                  : formik.values.sequenceType === SequenceType.cdsSequence
                    ? <BenchlingCdsInput formik={formik} schema={schema} />
                    : formik.values.sequenceType === SequenceType.conSequence
                      ? <BenchlingConstructInput formik={formik} schema={schema} />
                      : formik.values.sequenceType === SequenceType.customSequence
                        ? <CustomDnaInput formik={formik} schema={schema} />
                        : <CustomAAInput formik={formik} schema={schema} />
              }
              <br /> <br/> <Divider />
            </Grid>

            {/*Grid for auto completes*/}
            <Typography color="gray" variant="body2" m="auto"> Algorithm props </Typography>
            <Grid item xs={12}>
              <Grid container rowSpacing={2}
                    columnSpacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ m: 0, p: 0 }}>

                <Tooltip placement="top" title="How cofold should be calculated - used for MFE and GU calculation">
                  <Grid item xs={12} md={3}>
                      <CofoldTypeInput cof={cof} onCofChange={setCof}/>
                  </Grid>
                </Tooltip>
                <Tooltip placement="top" title="Algorithms that used this Run. Parameters are: MFE optimization,
                codon usage score optimization to codon usage table, GU reduction, GC content optimization">
                  <Grid item xs={12} md={4}>
                    <AlgoTypeInput formik={formik}/>
                  </Grid>
                </Tooltip>
                <Tooltip placement="top" title="Select a codon usage table,
                used for calculating the codon usage score(how similar the optimized sequence to codon usage table">
                  <Grid item xs={12} md={4}>
                    <CodonUsageInput formik={formik}/>
                  </Grid>
                </Tooltip>
              </Grid>
              <br /><br /><Divider />
            </Grid>

            {/*Repeats and restriction enzymes*/}
            <Typography color="gray" variant="body2" m="auto">Restrictions</Typography>
            <Grid item xs={12}>
              <Grid container rowSpacing={2}
                    columnSpacing={0}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ m: 0, p: 0 }}>
                <Tooltip placement="top" title="Multiple restriction enzymes can be selected">
                  <Grid item xs={12} sm={6} md={4}>
                    <RestrictionEnzymesAutocomplete enzymes={enzymes} onEnzymesChange={setEnzymes}/>
                  </Grid>
                </Tooltip>
                <Tooltip placement="top" title="Motifs that should be excluded from the result">
                  <Grid item xs={12} sm={6} md={4}>
                    <MotifsAutocomplete onMotifsChange={setMotifs}/>
                  </Grid>
                </Tooltip>
                <Tooltip placement="top" title="The minimum length from which the repeat should not occur">
                  <Grid item xs={12} sm={6} md={3}>
                    <CustomSlider length={repeatLength} onRepeatLengthChange={setRepeatLength} defaultValue={20}
                                  title={"Repeat length"} max={20} min={6} step={1}/>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
              <br />
              <Box textAlign="center">
                <Button variant="contained" color="success" type="submit"
                        endIcon={<SendIcon />}>Send</Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
    : <p>Loading...</p>
  )
}

export default NewRunComponent;