import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import PageHeader from "../components/common/page-header";
import {GetAxiosInstance} from "../components/common/GetAxiosInstance";

const cols = [
  { field: 'id', headerName: 'Sequence Id', type: 'number', flex: 1 },
  { field: 'sim', headerName: 'Simulation Id', type: 'number', flex: 1 },
  { field: 'gu_no', headerName: 'GU pairs', type: 'number', flex: 1 },
  { field: 'mfe', headerName: 'MFE', type: 'number', flex: 1 },
  { field: 'gc', headerName: 'GC content', type: 'number', flex: 1 },
  { field: 'codon_usage', headerName: 'Codon Usage', type: 'number', flex: 1 },
  { field: 'diff', headerName: 'Diff score', type: 'number', flex: 1 },
  { field: 'create_time', headerName: 'Create Time', flex: 2},
  { field: 'seq', headerName: 'Sequence', flex:3},
]

const SequenceList = () => {
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const axiosi = GetAxiosInstance();

    useEffect(() => {
        setLoading(true);
        axiosi.get(`/api/sequences`)
            .then((res) => setTableData(res.data))
            .finally(() => setLoading(false));
    }, [])

    return (
        <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
      >
        <PageHeader msg={"Sequences"} />
        <DataGrid autoHeight sx={{m: 1}}
                  rows={tableData}
                  density={'compact'}
                  columns={cols}
                  pageSize={15}
                  rowsPerPageOptions={[15]}
                  loading={loading}
        />
      </Box>
        
    )

}

export default SequenceList;