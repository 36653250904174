import {Slider, Typography} from "@mui/material";
import Box from "@mui/material/Box";

const CustomSlider = ({length, onRepeatLengthChange, defaultValue, title, min, max, step}) => {
  return (
      <Box>
        <Typography id="length-slider" gutterBottom>
          {title}: {length}
        </Typography>
        <Slider
          valueLabelDisplay="auto"
          step={step}
          marks
          min={min}
          max={max}
          defaultValue={defaultValue}
          onChange={(ev, val) => {
            ev.preventDefault()
            onRepeatLengthChange(val)
          }}
        />
      </Box>
  )
}

export default CustomSlider;