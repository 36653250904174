import {
  Button,
  Dialog,
  DialogActions, DialogContent,
  DialogTitle
} from "@mui/material";

const RemoveMotifDialog = ({open, handleClose, handleSuccess, motif}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirmation
      </DialogTitle>
      <DialogContent>
        {
          motif && <p>
            Are you sure you want to delete motif: {motif.id} - {motif.site}
          </p>
        }
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="success" onClick={handleSuccess}>Remove</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveMotifDialog;