import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {CofoldType} from "../../common/enums";
import React from "react";

const CofoldTypeInput = ({cof, onCofChange}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="cofold-type">Cofold</InputLabel>
      <Select
        labelId="cofold-type"
        id="cofold-type"
        sx={{ '& #cofold-type': { fontSize: 12, }}}
        value={cof}
        label="Cofold Type"
        onChange={(e) => onCofChange(e.target.value)}
      >
        {CofoldType.map(value => (
          <MenuItem key={value} value={value}>{value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CofoldTypeInput;