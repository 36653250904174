import axios from "axios";
import {useKeycloak} from "@react-keycloak/web";

export function GetAxiosInstance(){
    const axiosInstance = axios.create({
        timeout: 20000 // request timeout
    });
    const { keycloak, initialized } = useKeycloak();

  // request interceptor
  axiosInstance.interceptors.request.use(
      config => {
        if(keycloak && keycloak.authenticated){
          config.headers["Authorization"] = 'Basic ' + btoa("kernalbio" + ':' + "kernalbiopass");
        }

        return config;
      },
      error => {
        Promise.reject(error);
      }
  );

  return axiosInstance;
}



