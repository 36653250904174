import React, {useState, useEffect, useRef} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {Button, IconButton} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PageHeader from "../components/common/page-header";
import AlertDialog from "../components/common/alert-dialog";
import CircularProgressWithLabel from "../components/common/circular-progress";
import Grid from "@mui/material/Unstable_Grid2";
import GraphPlotDialog from "../components/graphs/graph-plot";
import ContinueSimulation from "../components/dialogs/select-continue";
import {GetAxiosInstance} from "../components/common/GetAxiosInstance";


const RunHistory = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openStart, setOpenStart] = useState(false)
  const [selectionModel, setSelectionModel] = useState([]);
  const [currentSimulation, setCurrentSimulation] = useState(0)
  const [openGraphPlot, setOpenGraphPlot] = useState(false)
  const [plotData, setPlotData] = useState(null)
  const timer_delay = 5000;
  const axiosi = GetAxiosInstance();

  useEffect(() => {
    setInterval(() => {
      console.log("POLL")
      axiosi.get(`/api/simulations`)
        .then((res) => {
          console.log(res.data)
          setTableData(res.data)
        })
    }, timer_delay);
  }, [])

  useEffect(() => {
    setLoading(true);
    axiosi.get(`/api/simulations`)
      .then((res) => {
        console.log(res.data)
        setTableData(res.data)
      })
      .finally(() => setLoading(false));
  }, [])

  function handleSelected(plotType){
    let data = {}
    selectionModel.forEach(el => data[`${el.algo}-${el.id}`] = el.id)

    axiosi.post(`/api/plot/${plotType}`, data).then((res) => {
      setOpenGraphPlot(true)
      setTimeout(() => setPlotData(res.data), 100)
    })
  }

  function stopSimulation(){
    console.log(`${currentSimulation} stop`)
    axiosi.post(`/api/stop-simulation/${currentSimulation}`, {id: currentSimulation}).then((res) => {
      window.location.reload(true)
    })
      .catch(function (error) { console.log(error.toJSON()); });
    setOpenCancel(false)
  }

  function startSimulation() {
    console.log(`${currentSimulation} start`)
    axiosi.post(`/api/start-simulation/${currentSimulation}`, {id: currentSimulation}).then((res) => {
        window.location.reload(true)
      })
      .catch(function (error) { console.log(error.toJSON()); });
    setOpenStart(false)
  }

  function continueSimulation() {
    console.log(`${currentSimulation} continue simulation`)
    axiosi.post(`/api/continue-simulation/${currentSimulation}`, {id: currentSimulation}).then((res) => {
      window.location.reload(true)
    })
      .catch(function (error) { console.log(error.toJSON()); });
    setOpenStart(false)
  }

  const cols = [
    { field: 'id', headerName: 'Id', type: 'number', flex: 1 },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'coding_region', headerName: 'CDS', flex: 2,
      renderCell: params => params.row.coding_region_name ?? params.row.coding_region
    },
    { field: 'algo', headerName: 'Algorithm', flex: 2 },
    {
      field: 'start_time',
      headerName: 'Start Date',
      flex: 2,
      type: 'string',
    },
    {
      field: 'end_time',
      headerName: 'End Date',
      flex: 2,
      type: 'string',
    },
    { field: 'status', headerName: 'Status', flex: 2},
    { field: 'current_generation', headerName: 'Current Gen', flex: 1},
    { field: 'progress', headerName: 'Progress', sortable: false, flex: 1,
      renderCell: params => (
          <CircularProgressWithLabel current={params.row.current_generation} max={params.row.max_generation} />
      )
    },
    { field: 'desc', headerName: 'Details', flex: 2},
    { field: 'start', headerName: 'Start', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <IconButton color="primary" aria-label="start simulation" onClick = {() => {
            setCurrentSimulation(params.row.id)
            setOpenStart(true)
          }}>
            <PlayCircleOutlineIcon />
          </IconButton>
        );
      }
    },
    { field: 'stop', headerName: 'Stop', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <IconButton color="error" aria-label="cancel simulation" onClick={() => {
            setCurrentSimulation(params.row.id)
            setOpenCancel(true)
          }}>
            <HighlightOffIcon />
          </IconButton>
        );
      }
    },
    { field: 'sequences', headerName: 'View', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <IconButton color="info" aria-label="show sequences" href={`/sequences/${params.row.id}`}>
            <InfoOutlinedIcon />
          </IconButton>
        );
      }
    }
  ]

  return (
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
      >
        <GraphPlotDialog open={openGraphPlot} setOpen={setOpenGraphPlot} plotData={plotData} />

        <AlertDialog handleClose={() => {setOpenCancel(false);}} open={openCancel} msgTitle={"Stop simulation"}
                     handleSuccess={stopSimulation}
                     msgContent={`Do you want really want to stop the simulation: ${currentSimulation}?`} />
        <ContinueSimulation
          handleClose={() => {setOpenStart(false);}} open={openStart} msgTitle={"Start/Continue simulation"}
          handleStart={startSimulation} msgContent={`Start/continue the simulation: ${currentSimulation}?`}
          handleContinue={continueSimulation}
        />

        <PageHeader msg={"Run history"} />
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <Button variant="outlined" color="primary" aria-label="Show graph plot" sx={{mx: 1}}
                    startIcon={<BarChartOutlinedIcon />} onClick={() => handleSelected('3d')}>
              Show 3d graph
            </Button>
            <Button variant="outlined" color="primary" aria-label="Show graph plot" sx={{mx: 1}}
                    startIcon={<BarChartOutlinedIcon />} onClick={() => handleSelected('3d-gc')}>
              Show 3d graph - GC
            </Button>
            <Button variant="outlined" color="primary" aria-label="Show graph plot" sx={{mx: 1}}
                    startIcon={<BarChartOutlinedIcon />} onClick={() => handleSelected('2d')}>
              Convergence Plot
            </Button>
            <DataGrid autoHeight sx={{m: 1}}
                      checkboxSelection
                      rows={tableData}
                      density={'compact'}
                      columns={cols}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        setSelectionModel(ids);
                        const selectedRows = tableData.filter((row) =>
                          selectedIDs.has(row.id),
                        );
                        setSelectionModel(selectedRows)
                      }}
                      loading={loading}/>
          </Grid>
        </Grid>
      </Box>
  )
}

export default RunHistory;