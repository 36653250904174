import {benchling_headers} from "../../common/enums";
import axios from "axios";

const urlDna = `/dna-sequences?pageSize=100`
const urlAA = `/aa-sequences?pageSize=100`

const setLocalStorage = (schema, setHasLoaded, axiosi) =>{
  if (!schema) return
  localStorage.setItem("benchling_url", schema.url)
  let token = localStorage.getItem(benchling_headers)

  if (token) {
    setHasLoaded(true)
    return
  }

  axiosi.get(`/api/benchling-token`).then((res) => {
    console.log("========================== SETTING API TOKEN =======================")
    let headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': res.data.basic_auth
    }

    headers = JSON.stringify(headers)
    localStorage.setItem(benchling_headers, headers)
  }).finally(() => setHasLoaded(true));
}

const setDnaOptions = (name, schema_id, setOptions, setLoading) => {
  let schema_query = schema_id ? `&schemaId=${schema_id}` : ''
  let name_query = name ? `&nameIncludes=${name}` : ''
  let headers = JSON.parse(localStorage.getItem(benchling_headers))
  if (!headers) return
  setLoading(true)
  let url = localStorage.getItem("benchling_url")
  axios({
    method: 'get',
    url: `${url}${urlDna}${name_query}${schema_query}`,
    headers: headers
  })
    .then((res) => setOptions(res.data.dnaSequences.filter(x => !!x.creator.name)))
    .finally(() => setLoading(false));
};

const setAaOptions = (name, setOptions, setLoading) => {
  let name_query = name ? `&nameIncludes=${name}` : ''
  let headers = JSON.parse(localStorage.getItem(benchling_headers))
  if (!headers) return
  setLoading(true)
  let url = localStorage.getItem("benchling_url")
  axios({
    method: 'get',
    url: `${url}${urlAA}${name_query}`,
    headers: headers
  })
    .then((res) => setOptions(res.data.aaSequences.filter(x => !!x.creator.name)))
    .finally(() => setLoading(false));
};

export {setDnaOptions, setAaOptions, setLocalStorage};
