import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Unstable_Grid2';
import List from "@mui/material/List";
import {setDnaOptions} from "../common/autocomplete-options";

const BenchlingConstructInput = ({formik, schema}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!schema) return;
    setDnaOptions(null, schema.con_id, setOptions, setLoading)
  }, [schema]);

  return (

    <Grid container rowSpacing={2}
          columnSpacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ m: 0, p: 0 }}>

      <Grid item xs={12}>
        <Autocomplete
          id="construct-dna-seq"
          options={options}
          onInputChange={(e, val) => {
            formik.setFieldValue('conSeq',val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('coding_region_name', obj?.name)
            formik.setFieldValue('coding_region_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                      <>
                        <span>Author: {option.creator.name}</span> <br />
                        <span>Registry:  {option.entityRegistryId}</span>
                      </>
                    }
                  />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="Benchling Construct (CON)*"
                       error={formik.touched.conSeq && Boolean(formik.errors.conSeq)}
                       helperText={formik.touched.conSeq && formik.errors.conSeq}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
        />
      </Grid>
    </Grid>
  )
}

export default BenchlingConstructInput;