import "./graph-plot.css"
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {useEffect} from "react";


const GraphPlotDialog = ({open, setOpen, plotData}) => {
  function onGraphPlotClose() {
    setOpen(false)
    let frame = document.getElementById('graphFrame')
    frame.innerHtml = ""
  }

  useEffect(() => {
    if (!open){
      console.log("closed")
      return
    }
    console.log("START")
    if (plotData) console.log("PLOTDATA")
    let frame = document.getElementById('graphFrame')
    if (!frame) return
    console.log("WRAPPER OPEN")
    console.log(frame)
    frame.innerHtml = ""
    let newWindow = window.open("#", "graphFrame")
    newWindow.document.write('<div id="graph_sequence_info" style="width: 98%;" className="graph_sequence_info">' +
      '<p id="sequence_id"></p><p id="sequence_string" style="overflow-wrap: break-word; font-size: 0.8em; font-family: \'Courier New\'"></p>' +
      '</div>')
    newWindow.document.write(plotData)
  }, [plotData, open])

  return (
    <Dialog
      open={open}
      onClose={onGraphPlotClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        Plot Graph
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{py: 0.5}}>
          <Grid item xs={12}>
            <div id="iframe-container">
              <iframe title="Graph plot" id="graphFrame" className="graphFrame" name="graphFrame"></iframe>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Button variant="outlined" onClick={onGraphPlotClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default GraphPlotDialog;