import {Divider, Typography} from "@mui/material";
import React from "react";

const PageHeader = ({msg}) => {
  return (
    <>
      <Typography variant="h5" component="h5" sx={{px: 1}}> {msg} </Typography>
      <br/>
      <Divider />
      <br/>
    </>
  )
}

export default PageHeader;
