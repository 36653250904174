import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import {ListItem, ListItemButton, ListItemText} from '@mui/material';
import { mainNavbarItems } from './navbarItems';
import { navbarStyles } from './styles';
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import {ListItemIcon} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import {useKeycloak} from "@react-keycloak/web";


export const Navbar = () => {
  const navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();

  return (
    <Box
      component="nav"
      sx={{ width: { sm: navbarStyles.drawer.width }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        sx={navbarStyles.drawer}
        variant="permanent"
        anchor="left"
        PaperProps={{
          elevation: 4,
        }}
      >
        <List>
        {
          mainNavbarItems.map((item, index) => (
            <ListItem
              key={item.id}
              onClick={() => navigate(item.route)}>
              <ListItemButton>
                <ListItemIcon
                  sx={navbarStyles.icons}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  sx={navbarStyles.text}
                  primary={item.label}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {!!keycloak.authenticated && (
            <ListItem
              key={100}
              onClick={() => keycloak.logout()}>
              <ListItemButton>
                <ListItemIcon
                  sx={navbarStyles.icons}>
                  {<LogoutIcon />}
                </ListItemIcon>
                <ListItemText
                  sx={navbarStyles.text}
                  primary={`Log out: ${keycloak.tokenParsed.preferred_username}`}
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Drawer>
    </Box>
  );
};