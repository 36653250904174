import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Grid from '@mui/material/Unstable_Grid2';
import List from "@mui/material/List";
import {setAaOptions, setDnaOptions} from "../common/autocomplete-options";

const BenchlingCdsInput = ({formik, schema}) => {
  const [utr5List, setUtr5List] = useState([])
  const [utr3List, setUtr3List] = useState([])
  const [cdsList, setCdsList] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!schema) return;
    setDnaOptions(null, schema.utr5_id, setUtr5List, setLoading)
    setDnaOptions(null, schema.utr3_id, setUtr3List, setLoading)
    setDnaOptions(null, schema.cds_id, setCdsList, setLoading)
  }, [schema]);


  return (

    <Grid container rowSpacing={2}
          columnSpacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ m: 0, p: 0 }}>

      <Grid item xs={12} md={3}>
        <Autocomplete
          id="cds-utr5-auto"
          options={utr5List}
          onInputChange={(e, val) => {
            formik.setFieldValue('utr5',val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('utr5_name', obj?.name)
            formik.setFieldValue('utr5_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                      <>
                        <span>Author: {option.creator.name}</span> <br />
                        <span>Registry:  {option.entityRegistryId}</span>
                      </>
                    } />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="5' UTR"
                       error={formik.touched.utrs && Boolean(formik.errors.utrs)}
                       helperText={formik.touched.utrs && formik.errors.utrs}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>
        }
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <Autocomplete
          id="amino-auto"
          options={cdsList}
          onInputChange={(e, val) => {
            formik.setFieldValue('cdsSeq',val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('coding_region_name', obj?.name)
            formik.setFieldValue('coding_region_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                    <>
                      <span>Author: {option.creator.name}</span> <br />
                      <span>Registry:  {option.entityRegistryId}</span>
                    </>
                    }
                  />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="Benchling Coding Sequence (CDS)*"
                       error={formik.touched.cdsSeq && Boolean(formik.errors.cdsSeq)}
                       helperText={formik.touched.cdsSeq && formik.errors.cdsSeq}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}
            />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          id="cds-utr3-auto"
          options={utr3List}
          onInputChange={(e, val) => {
            formik.setFieldValue('utr3',val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('utr3_name', obj?.name)
            formik.setFieldValue('utr3_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                    <>
                      <span>Author: {option.creator.name}</span> <br />
                      <span>Registry:  {option.entityRegistryId}</span>
                    </>
                  }
                  />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="3' UTR"
                       error={formik.touched.utrs && Boolean(formik.errors.utrs)}
                       helperText={formik.touched.utrs && formik.errors.utrs}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
        />
      </Grid>
    </Grid>
  )
}

export default BenchlingCdsInput;