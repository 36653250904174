import {Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const ContinueSimulation = ({open, msgTitle, msgContent, handleStart, handleContinue, handleClose}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        {msgTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {msgContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <ButtonGroup variant="outlined" color="primary" aria-label="Show graph plot" size="small">
          <Button onClick={handleStart}>
            Start new optimization
          </Button>
          <Button onClick={handleContinue}>
            Continue from previous optimization
          </Button>
        </ButtonGroup>

        <Button variant="contained" onClick={handleClose} autoFocus size="small" sx={{marginX: "4em"}}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ContinueSimulation;