import './App.css';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import {Navbar} from "./components/nav-bar/Navbar";
import Box from "@mui/material/Box";
import NewRun from "./pages/NewRun";
import RunHistory from "./pages/RunHistory";
import SequenceList from "./pages/SequenceList";
import Settings from "./pages/Settings";
import Motifs from "./pages/Motifs";
import PrivateRoute from "./common/PrivateRoute";
import SimulationSequenceList from "./pages/SimulationSequenceList";


function App() {
  return (
    <Box sx={{ display: "flex", p: 4 }}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<PrivateRoute> <Home /> </PrivateRoute>}>
            <Route path="start-algo" element={<PrivateRoute> <NewRun /> </PrivateRoute>} />
            <Route path="run-history" element={<PrivateRoute> <RunHistory /> </PrivateRoute>} />
            <Route path="sequences" element={<PrivateRoute> <SequenceList /> </PrivateRoute>} />
            <Route path="sequences/:id" element={<PrivateRoute> <SimulationSequenceList /> </PrivateRoute>} />
            <Route path="motifs" element={<PrivateRoute> <Motifs /> </PrivateRoute>} />
            <Route path="settings" element={<PrivateRoute> <Settings /> </PrivateRoute>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Box>
  )
}

function Home() {
  return (
    <>
      <Outlet />
    </>
  )
}


export default App;