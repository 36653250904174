import React, {useState, useEffect, useCallback} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {Button, IconButton} from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddMotifDialog from "../components/dialogs/new-motif";
import CustomDialog from "../components/common/custom-dialog";
import RemoveMotifDialog from "../components/dialogs/remove-motif";
import PageHeader from "../components/common/page-header";
import {GetAxiosInstance} from "../components/common/GetAxiosInstance";


const Motifs = () => {
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(false)
  const url = `/api/motifs`
  const [openAddMotif, setOpenAddMotif] = useState(false)
  const [openDeleteMotif, setOpenDeleteMotif] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)
  const [motifData, setMotifData] = useState(null)
  const [selectedMotif, setSelectedMotif] = useState("")
  const axiosi = GetAxiosInstance();


  const cols = [
    { field: 'id', headerName: 'Id', type: 'number', flex: 1, editable: false },
    { field: 'site', headerName: 'Site', flex: 8, editable: false },
    { field: 'delete', headerName: 'Delete', sortable: false, flex: 1,
      renderCell: (params) => {
        return (
          <IconButton color="error" aria-label="delete motifs" onClick={() => handleRemoveMotif(params.row)}>
            <RemoveCircleOutlineOutlinedIcon />
          </IconButton>
        );
      }
    }
  ]

  const handleAddMotif = () => setOpenAddMotif(true);

  const handleRemoveMotif = (row) => {
    console.log(row.id)
    let selected = tableData.filter(obj => obj.id === row.id)[0]
    setSelectedMotif(selected)
    setOpenDeleteMotif(true)
  }

  const handleRemoveSuccess = () => {
    setOpenDeleteMotif(false)
    if (!selectedMotif) return

    setLoading(true)
    axiosi.delete(`${url}/${selectedMotif.id}`).then((res) => {
      console.log(res.status)
      console.log(res.data)
      console.log("remove success")
      setOpenSuccess(true)
    })
      .catch(function (error) {
        console.log(error.toJSON());
        setOpenError(true)
      })
      .finally(() => {
        setLoading(false)
        getMotifs()
      });
  }

  const handleAddSuccess = () => {
    console.log("success")
    console.log([motifData])
    setLoading(true)
    axiosi.post(url, [motifData]).then((res) => {
      console.log(res.status)
      console.log(res.data)
      setTableData([...tableData, {id: res.data.id, site: res.data.site }])
      setOpenSuccess(true)
    })
      .catch(function (error) {
        console.log(error.toJSON());
        setOpenError(true)
      })
      .finally(() => {
        setLoading(false)
        setOpenAddMotif(false)
      });
  }

  const getMotifs = useCallback(() => {
    setLoading(true)
    axiosi.get(url).then((res) => setTableData(res.data))
      .finally(() => setLoading(false));
  }, [url])

  useEffect(() => {
    getMotifs();
  }, [getMotifs])


  return (
    <>
      <AddMotifDialog open={openAddMotif} handleClose={() => setOpenAddMotif(false)}
                      setMotifData={setMotifData} handleSuccess={handleAddSuccess}/>
      <RemoveMotifDialog open={openDeleteMotif} handleClose={() => setOpenDeleteMotif(false)}
                      motif={selectedMotif} handleSuccess={handleRemoveSuccess}/>
      <CustomDialog handleClose={() => setOpenSuccess(false)} open={openSuccess} msgTitle={"Success"}
                    color={"success"} msgContent={"Operation successfully completed!"} />
      <CustomDialog handleClose={() => setOpenError(false)} open={openError} msgTitle={"Error"}
                    color={"error"} msgContent={"Operation was unsuccessful"} />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
      >
        <PageHeader msg={"Motifs"} />
        <Button variant="outlined" color="success" aria-label="Add new motif" sx={{mx: 1}}
                startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleAddMotif}>
          Add new motif
        </Button>
        <DataGrid
          autoHeight sx={{m: 1}}
          rows={tableData}
          density={'compact'}
          columns={cols}
          pageSize={20}
          rowsPerPageOptions={[20]}
          loading={loading}
        />
      </Box>
    </>
  )
}

export default Motifs;