import Grid from "@mui/material/Unstable_Grid2";
import {Autocomplete, ListItem, ListItemText, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import List from "@mui/material/List";
import {setDnaOptions} from "../common/autocomplete-options";


const CustomDnaInput = ({formik, schema}) => {
  const [utr5List, setUtr5List] = useState([])
  const [utr3List, setUtr3List] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!schema) return;
    setDnaOptions(null, schema.utr5_id, setUtr5List, setLoading)
    setDnaOptions(null, schema.utr3_id, setUtr3List, setLoading)
  }, [schema]);


  return (

    <Grid container rowSpacing={2}
          columnSpacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ m: 0, p: 0 }}>

      <Grid item xs={12} md={3}>
        <Autocomplete
          id="custom-utr5-auto"
          options={utr5List}
          onInputChange={(e, val) => {
            // setDnaOptions(val, schema.utr5_id, setUtr5List, setLoading)
            formik.setFieldValue('utr5', val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('utr5_name', obj?.name)
            formik.setFieldValue('utr5_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                      <>
                        <span>Author: {option.creator.name}</span> <br />
                        <span>Registry:  {option.entityRegistryId}</span>
                      </>
                    } />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="5' UTR"
                       error={formik.touched.utrs && Boolean(formik.errors.utrs)}
                       helperText={formik.touched.utrs && formik.errors.utrs}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField id="customSeq" label="DNA/RNA Sequence*" variant="standard"
                   value={formik.values.customSeq}
                   onChange={(e) => {formik.setFieldValue('customSeq', e.target.value.toUpperCase().replace(/[^ACGTU]/g, ""))}}
                   onBlur={formik.handleBlur}
                   error={formik.touched.customSeq && Boolean(formik.errors.customSeq)}
                   helperText={formik.touched.customSeq && formik.errors.customSeq}
                   fullWidth/>
        {/*e.target.value.toUpperCase().replace(/[^ACGTU]/g, ""))*/}
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          id="custom-utr3-auto"
          options={utr3List}
          onInputChange={(e, val) => {
            formik.setFieldValue('utr3',val)
          }}
          onChange={(e, obj) => {
            formik.setFieldValue('utr3_name', obj?.name)
            formik.setFieldValue('utr3_link', obj?.apiURL)
          }}
          loading={loading}
          getOptionLabel={(option) => option.bases || ""}
          renderOption={(props, option) =>
            (
              <List {...props} key={option.id}>
                <ListItem>
                  <ListItemText
                    primary={option.name}
                    secondary={
                      <>
                        <span>Author: {option.creator.name}</span> <br />
                        <span>Registry:  {option.entityRegistryId}</span>
                      </>
                    }
                  />
                </ListItem>
              </List>
            )}
          filterOptions={(x) => x}
          isOptionEqualToValue={(option, value) => true}
          renderInput={(params) =>
            <TextField {...params} label="3' UTR"
                       error={formik.touched.utrs && Boolean(formik.errors.utrs)}
                       helperText={formik.touched.utrs && formik.errors.utrs}
                       InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
        />
      </Grid>
    </Grid>
  )
}

export default CustomDnaInput;

