import React, {useEffect, useState} from 'react'
import Box from "@mui/material/Box";
import Grid from '@mui/material/Unstable_Grid2';
import {Button, TextareaAutosize } from "@mui/material";
import PageHeader from "../components/common/page-header";
import {GetAxiosInstance} from "../components/common/GetAxiosInstance";

const Settings = () => {
  const [configData, setConfigData] = useState("")
  const [loading, setLoading] = useState(false)
  const axiosi = GetAxiosInstance();

  function handleSave(e){
    setLoading(true);
    e.preventDefault()
    axiosi.post("/api/configs", configData).then((res) => {
      console.log(res.status)
      console.log(res.data)
      localStorage.clear()
    })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);
    axiosi.get(`/api/configs`).then((res) => {
        let pretty = JSON.stringify(res.data, undefined, 2);
        setConfigData(pretty)
      })
      .finally(() => setLoading(false));
  }, [])

  return (
    !loading ?
    <Box
      component="main"
      sx={{ flexGrow: 1, bgcolor: 'background.default', p: 4, boxShadow: 2 }}
    >
      <PageHeader msg={"Settings"} />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextareaAutosize
            id="config"
            name="config"
            maxRows={48}
            placeholder="Minimum 3 rows"
            defaultValue={configData}
            autoComplete="config"
            variant="outlined"
            onChange={(e) => {setConfigData(e.target.value)}}
            style={{ width: "60%", backgroundColor: "#fefef2", borderRadius: 4 }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="outlined" color="success" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>
    </Box>
    : <p>Loading...</p>
    )
}

export default Settings;