import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ListIcon from '@mui/icons-material/List';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';


export const mainNavbarItems = [
    {
        id: 1,
        icon: <PlayArrowIcon />,
        label: 'New Run',
        route: 'start-algo'
    },
    {
        id: 3,
        icon: <HistoryOutlinedIcon />,
        label: 'Run history',
        route: 'run-history'
    },
    {
        id: 4,
        icon: <ListIcon />,
        label: 'Sequences',
        route: 'sequences'
    },
    {
        id: 5,
        icon: <ListIcon />,
        label: 'Motifs',
        route: 'motifs'
    },
    {
        id: 6,
        icon: <SettingsApplicationsIcon />,
        label: 'Settings',
        route: 'settings'
    }
]