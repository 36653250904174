const CofoldType = [
  'COFOLD', 'VIENNA_RNA', 'LINEAR_FOLD_V', 'LINEAR_FOLD_C', 'LINEAR_FOLD_E'
]

const SequenceType = {
  aaSequence: 1,
  cdsSequence: 2,
  conSequence: 3,
  customSequence: 4,
  customAA: 5,
}

const benchling_headers = "benchling_headers"

export {CofoldType, SequenceType, benchling_headers};