import { Autocomplete, TextField } from "@mui/material";
import React, {useEffect, useState} from "react";
import {GetAxiosInstance} from "../common/GetAxiosInstance";

const CodonUsageInput = ({formik}) => {
  const [usageData, setUsageData] = useState([])
  const axiosi = GetAxiosInstance();

  useEffect(() => {
    axiosi.get(`/api/codon-usages`).then((res) => {
        setUsageData(res.data)
      })
  }, [])

  return (
    <Autocomplete
      disablePortal
      id="codon-usage-auto"
      options={usageData}
      value={formik.values.codonUsage}
      onChange={(event, inputValue) => formik.setFieldValue("codonUsage", inputValue)}
      getOptionLabel={(option) => option || ""}
      renderInput={(params) =>
        <TextField {...params} label="Codon Usages*"
                   error={formik.touched.codonUsage && Boolean(formik.errors.codonUsage)}
                   helperText={formik.touched.codonUsage && formik.errors.codonUsage}
                   InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}/>}
    />
  )
}

export default CodonUsageInput;